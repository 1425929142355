function trigger(name, element, detail) {
  const EventClass = detail ? CustomEvent : Event

  const evt = new EventClass(name, {
    view: window,
    bubbles: true,
    cancelable: true,
    detail,
  })
  element.dispatchEvent(evt)
}


function _isTextChangeEvent(event) {
  return (
    (event.keyCode === 0 || event.keyCode === 229)
    || event.key.length === 1 || ['Backspace', 'Delete'].includes(event.key)
  )
}


/**
 * @param {KeyboardEvent} event
 * @param {number?} index
 * @param {number} maxIndex
 * @param {{
 *  setIndex?: function(number): void,
 *  onSelect?: function(number): void,
 *  onBlur?: function(): void,
 * }}
 */
function handleIndexKeyboardEvent(event, index, maxIndex, {setIndex, onBlur, onSelect, onKeypress, wrap=true}) {
  let indexValue
  let eventHandled = true

  switch (event.key) {
    case 'ArrowUp':
      indexValue = (index ?? 0) -1
      break

    case 'ArrowDown':
      indexValue = (index ?? -1) +1
      break

    case 'Escape':
      onBlur?.()
      break

    case 'Enter':
      onSelect?.(index)
      break

    default:
      if (_isTextChangeEvent(event)) {
        onKeypress?.(event)
      }
      eventHandled = false
  }

  if (indexValue !== undefined && maxIndex >= 0) {
    if (wrap) {
      while (indexValue < 0) {
        indexValue += (maxIndex + 1)
      }
      indexValue %= (maxIndex + 1)
    } else {
      indexValue = Math.min(Math.max(0, indexValue), maxIndex)
    }
    setIndex(indexValue)
  }

  if (eventHandled) {
    event.preventDefault()
  }

  return eventHandled
}

export default {
  trigger,
  handleIndexKeyboardEvent,
}
