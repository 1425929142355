function setItemState($items, $from, $to, state) {
  let start = $items.indexOf($from)
  let end = $items.indexOf($to)

  if (start === -1 || end === -1 || start === end) {
    return
  }

  if (start > end) {
    [start, end] = [end, start]
  }

  for (let i = start; i <= end; i += 1) {
    $items[i].checked = state
  }
}


export function dataShiftGroup(_help, $elem, event) {
  const state = $elem.checked

  const shiftgroup = $elem.dataset.shiftgroup
  const selector = (
    shiftgroup
      ? `[data-shiftgroup=${shiftgroup}]`
      : '[data-shiftgroup]'
  )

  if (globalThis.lastSelected && event.shiftKey) {
    const items = [...document.querySelectorAll(selector)]

    setItemState(items, globalThis.lastSelected, $elem, state)
  }

  globalThis.lastSelected = $elem
}

export default {dataShiftGroup}
