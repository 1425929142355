/**
 * @module bulma-events
 */
import buildHelp from './bulma-help'
import * as bulmaHelp from './bulma-help'
import '../polyfills/closest-polyfill'
import events from '../events'

import {dataShiftGroup} from './data-shiftgroup'
import {dataSelectOnclick} from './data-select-onclick'
import {dataZoom} from './data-zoom'
import dataCheckAll from './data-check-all'


function addLoader(help, $elem) {
  help.addLoader($elem, $elem.dataset?.loaderTimeout)
  const isAsync = $elem.hasAttribute('data-async-target')

  if (!isAsync) {
    window.addEventListener(
      'pagehide',
      () => help.removeLoader($elem),
    )
  }
}


function getForm($element) {
  return $element.form || $element.closest('form')
}


/** Facendo click sui nodi con attributo `data-action` viene
 * effettuato submit della form che contiene l'attributo.
 *
 * Prima di effettuare submit, il valore del campo della form con
 * nome `action` viene impostato al valore di `data-action`.
 *
 * Se sullo stesso nodo e` valorizzato `data-async-target`, la form
 * viene inviata in modo asincrono e il testo di risposta usato
 * per rimpiazzare l'elemento selezionato dal selettore contenuto in
 * `data-async-target`.
 *
 * Se il nodo ha data-async-target e data-async-event, alla risposta
 * dell'invio asincrono del form viene emesso un evento custom con
 * il nome del valore di data-async-event, e event.detail conterra`
 * il valore dell'attributo data-event-detail.
*/
function dataAction(_help, $elem, event) {
  const $form = getForm($elem)

  if ($form === null) {
    console.error('Unable to find a <form/> for element: ', $elem)
    return
  }
  if (!$form.elements.action) {
    console.error('Unable to perform data-action: missing <input name="action"/> on', $form)
    return
  }

  $form.elements.action.value = $elem.dataset.action
  event.preventDefault()
}

function doFormSubmit($elem, $form) {
  const extraParams = bulmaHelp.getExtraParams($elem)
  _setExtraParams($elem, extraParams)
  $form.submit()
}

function _setExtraParams($elem, params) {
  const containerId = 'bulma-extra-params'
  let $container = document.getElementById(containerId)
  if (!$container) {
    $container = document.createElement('span')
    $container.id = containerId
  } else {
    $container.innerHTML = ''
  }

  for (const [name, value] of params) {
    const $input = document.createElement('input')
    $input.name = name
    $input.value = value
    $input.type = 'hidden'
    $container.appendChild($input)
  }
  $elem.after($container)
}


let timeoutId = undefined

/**
 * Listens for change events on an element, when triggered
 * form.submit() is called (synchronously or async depending on the
 * existence of data-async-target)
 */
function dataChange(help, element) {
  help.addLoader(element, element.dateset?.loaderTimeout)
  const form =  getForm(element)
  if (element.dataset.change) {
    if (form.elements.action === undefined) {
      console.warn('Unable to perform data-change: missing field <input name="action"/> in', form)
      form.action = element.dataset.change
    } else {
      form.elements.action.value = element.dataset.change
    }
  }

  const isAsync = element.hasAttribute('data-async-target')
  if (!isAsync) {
    doFormSubmit(element, form)
    return
  }

  const delayed = () => {
    help.ajaxFormLoad(element, form)
  }

  const isDeferred = element.hasAttribute('data-has-timeout')
  if (isDeferred) {
    clearTimeout(timeoutId)
    let delay = element.dataset.hasTimeout
    if (!delay) {
      delay = 1000
    }
    timeoutId = setTimeout(delayed, delay)

  } else {
    delayed()
  }
}



function dataKeyUp(help, element, event) {
  if (event.key === 'Enter') {
    return

  }
  const $form = getForm(element)
  if (!$form) {
    console.error('Elements with data-keyup must be inside a form element', element)
    return
  }

  if (element.dataset.keyup) {
    if ($form.elements.action === undefined) {
      console.warn('Unable to perform data-keyup: missing field <input name="action"/> in', $form)
      $form.action = element.dataset.keyup
    } else {
      $form.elements.action.value = element.dataset.keyup
    }
  }

  const isAsync = element.hasAttribute('data-async-target')

  const delayed = () => {
    if (!isAsync) {
      doFormSubmit(element, $form)
    } else {
      help.ajaxFormLoad(element, $form)
    }
  }

  clearTimeout(timeoutId)
  const delay = 300
  timeoutId = setTimeout(delayed, delay)

}


const dataReplace =
  help =>
    element => {
      help.addLoader(element, element.dataset?.loaderTimeout)
      help.ajaxLinkLoad(element)
    }


const dataToggler =
  element => {
    const selector = element.dataset.toggler
    const targets = document.querySelectorAll(selector)

    for (const target of targets) {
      if (target.classList.contains('is-active')) {
        target.classList.remove('is-active', 'to-be-closed')
      } else {
        target.classList.add('is-active', 'to-be-closed')
      }
    }
  }


function bulmaUpload(element) {
  const files = Array.from(element.files).map(file => file.name)
  const fileList = files.join(', ')
  const description = element.parentElement.querySelector('.file-name')
  if (description) {
    description.textContent = fileList
  }
}


export const removeElements =
  help =>
    () => {
      help.getRoot()
        .querySelectorAll('.to-be-removed')
        .forEach(e => e.remove())
    }


/* bulma gui */
function closeElements() {
  let dropdown
  const targets = document.querySelectorAll('.to-be-closed')

  for (const target of targets) {
    target.classList.remove('is-active')
    target.classList.remove('to-be-closed')
    if (target.hasAttribute('data-dropdown-template')) {
      dropdown = target.querySelector('.dropdown-menu')
      dropdown.remove()
    }
  }
}


/**
  * Locks scroll of window page.
  * If an element is provided and [data-scroll-top] exists on that element,
  * the page scrolls to the top of the screen before locking.
  *
  * @param {Element} [element]
  *
  * @see unlockScroll
  */
function lockScroll(element) {
  if (element !== undefined && element.dataset.scrollTop) {
    window.scrollTo(0, 0)
  }
  document.querySelector('html').classList.add('lock-scroll')
}


/**
  * Unlock window scroll, previously locked with lockScroll.
  * @see lockScroll
  */
function unlockScroll() {
  document.querySelector('html').classList.remove('lock-scroll')
}


function closeModal($elem) {
  unlockScroll()
  const $modal = $elem.closest('.modal')
  const $container = $elem.closest('[data-container-for]')

  if ($container) {
    $container.remove()
  } else {
    $modal.remove()
  }
}

function showModal(help, element) {
  const root = help.getRoot()

  let body = root.querySelector('form')

  if (!body) {
    body = root.querySelector('body') || root
  }

  const selector = element.dataset.modalTemplate
  const modal = help.renderTemplate(selector, element)

  body.append(modal)
  lockScroll(element)
}

function showRemote(help, $elem, event) {
  event.stopImmediatePropagation()
  help.addLoader($elem, 10000)
  const template = document.createElement('template')

  const $root = help.getRoot()
  const $body = $root.querySelector('body') || $root
  const $form = $elem.closest('form') || $body

  const replace = html => {
    help.removeLoader($elem)
    template.innerHTML = html

    const content = template.content

    // if modal contains a form, append it outside the form element
    const $target = (
      content.querySelector('form')
        ? $body
        : $form
    )
    $target.append(content)

    lockScroll($elem)
  }

  const hrefValorized = (
    ($elem.hasAttribute('href') && $elem.tagName !== 'A') || $elem.getAttribute('href')
  )

  if (hrefValorized) {
    help.getLink($elem.href, replace)

  } else {
    const $form = getForm($elem)
    if (!$form.elements.action) {
      console.warn('current form does not have an input $elem named "action":', $form)
      if ($elem.dataset.modalRemote) {
        $form.action = $elem.dataset.modalRemote
      }
    } else if ($elem.dataset.modalRemote && !$elem.dataset.action) {
      $form.elements.action.value = $elem.dataset.modalRemote
    }

    const extraParams = bulmaHelp.getExtraParams($elem)
    help.sendForm($form, (html) => {
      replace(html)
    }, extraParams, $form.method)
  }
}



/**
 * Toggle modal + lock/unlock window scroll
 */
async function loadModal(help, elem) {
  const identifier = elem.dataset.modal
  const link = elem.attributes.href.value
  const $root = help.getRoot()
  const body = $root.querySelector('body') || $root

  const modalContainer = body.querySelector(`[data-container-for="${identifier}"]`)

  if (modalContainer && modalContainer.hasAttribute('loading')) {
    modalContainer.remove()
  } else if (modalContainer) {
    unlockScroll(help)
    modalContainer.remove()
    return
  }

  lockScroll(elem)
  const template = document.createElement('template')

  template.innerHTML = `<span data-container-for="${identifier}" loading></span>`
  body.append(template.content)
  const $elem = document.querySelector(`[data-container-for=${identifier}]`)

  try {
    const response = await fetch(link)
    const html = await response.text()

    $elem.outerHTML = `<span class="to-be-removed" data-container-for="${identifier}">${html}</span>`
  } catch (err) {
    console.error(err)
    unlockScroll(elem)
  }
}


/*
 * Close modal template + unlock window scroll
 */
export const dataCloseModal =
  help =>
    async elem => {
      const identifier = elem.dataset.closeModal
      unlockScroll(help)
      document
        .querySelector(`[data-container-for="${identifier}"]`)
        ?.remove()
    }


function dataAltIcon(_help, $elem) {
  const altIcon = $elem.dataset.altIcon
  const icon = $elem.querySelector('i')

  if (icon) {
    const prevClass = icon.className
    icon.className = altIcon
    $elem.dataset.altIcon = prevClass
  }
}

const toggleHidden =
  help =>
    element => {
      const selector = element.dataset.show

      if (selector) {
        const targets = help.getRoot().querySelectorAll(selector)

        for (const target of targets) {
          target.classList.toggle('is-hidden')
        }
      }
    }


function hideMessage(element) {
  const target = element.closest('article.message')
  target.classList.add('is-hidden')
}


function toggleActive(element) {
  const targetId = element.dataset.target
  const target = document.getElementById(targetId)
  element.classList.toggle('is-active')
  target.classList.toggle('is-active')
}

function toggleTab(element) {
  const others = element.parentElement.children
  let panels, selector
  for (const other of others) {
    selector = other.dataset.tabtoggler
    panels = document.querySelectorAll(selector)
    if (other.isSameNode(element)) {
      other.classList.add('is-active')
      panels.forEach((elem) => elem.classList.remove('is-hidden'))
    } else {
      other.classList.remove('is-active')
      panels.forEach((elem) => elem.classList.add('is-hidden'))
    }
  }
}

function toggleDropdown (element, event) {
  const content = event.target.closest('.dropdown-content')
  if (!content) {
    if (element.classList.contains('is-active')) {
      element.classList.remove('is-active')

    } else {
      element.classList.add('is-active', 'to-be-closed')
    }
  }
}

const showDropdown = help => (element, event) => {
  if (element.classList.contains('is-active')) {
    if (!event.target.closest('.dropdown-menu')) {
      element.querySelector('.dropdown-menu').remove()
      element.classList.remove('is-active', 'to-be-closed')
    }

  } else {
    const timer = element.dataset.timeoutId
    if (timer) {
      clearTimeout(timer)
    }
    const selector = element.dataset.dropdownTemplate
    const modal = help.render_template(selector, element)
    element.append(modal)
    element.classList.add('is-active', 'to-be-closed')
  }
}


const clickMouseover =
  help => (element) => {
    const root = help.getRoot()
    const [selector, timer] = element.dataset.triggerClick.split('::')
    const defaultTimer = 500

    if (selector) {
      const target = root.querySelector(selector)
      if (target) {
        target.dataset.timeoutId = setTimeout(() => target.click(), timer ?? defaultTimer)
      }
    } else {
      // TODO: uncomment the line below
      // toggler.dataset.timeoutId = setTimeout(() => toggler.click(), timer ?? defaultTimer)
    }
  }


const unclickMouseout =
  help => (element) => {
    const root = help.getRoot()
    const [selector] = element.dataset.triggerClick.split('::')
    if (selector) {
      const target = root.querySelector(selector)
      if (target) {
        clearTimeout(target.dataset.timeoutId)
        target.dataset.timeoutId = false
      }
    } else {
      // TODO: uncomment the lines below
      // clearTimeout(toggler.dataset.timeoutId)
      // toggler.dataset.timeoutId = false
    }
  }


function resizeTextarea(element) {
  const base = element.clientHeight
  const tobe = element.scrollHeight
  if (tobe > base) {
    element.style.height = tobe + 5 + 'px'
  }
}

function dataEnterTarget($elem, event) {
  if (event.key !== 'Enter') {
    return
  }
  event.preventDefault()
  if (event.target.tagName === 'TEXTAREA') {
    return
  }


  const cssSelector = $elem.dataset.enterTarget
  const $target = document.querySelector(cssSelector)

  events.trigger('click', $target, MouseEvent)
}


function printNewWindow(element) {
  // Create hidden iframe with specified id
  const createFrame = (id) => {
    const iframe = document.createElement('iframe')
    iframe.id = id

    Object.assign(iframe.style, {
      visibility: 'hidden',
      position: 'fixed',
      right: 0,
      bottom: 0,
    })

    document.body.appendChild(iframe)

    iframe.onload = () => {
      iframe.contentWindow.focus() /* Required for IE 10 */
      iframe.contentWindow.print()
    }
    return iframe
  }

  // short circuit eveluation: create only one iframe window
  const iframeID = '#print-iframe-window'
  const iframe = document.getElementById(iframeID) || createFrame(iframeID)

  iframe.src = element.href
}


function formEncType(_help, $element) {
  const $form = getForm($element)
  const formenctype = $element.getAttribute('formenctype')

  if (formenctype) {
    $form.setAttribute('enctype', formenctype)
  }
}

function formAction(_help, $element) {
  const $form = getForm($element)
  const formaction = $element.getAttribute('formaction')

  if (formaction) {
    $form.setAttribute('action', formaction)
  }
}


function onLoadEvents(help, $fragment) {
  const $root = help.getRoot()
  $root.querySelectorAll('[data-check-all]').forEach(
    $elem => dataCheckAll.onLoad(help, $elem),
  )


  $root.querySelectorAll('[data-counter-of]').forEach(
    $elem => {
      const selector = $elem.getAttribute('data-counter-of')
      const count = $root.querySelectorAll(selector).length.toString()

      // required for triggering a mutation-observer loop
      if ($elem.textContent !== count) {
        $elem.textContent = count
      }
    },
  )

  $fragment.querySelectorAll('[data-click-onload]').forEach(
    $elem => {
      const delay = $elem.dataset.clickOnload
      const delayed = () => {
        $elem.click()
        $elem.removeAttribute('data-click-onload')
      }
      if (delay) {
        setTimeout(delayed, delay)
      } else {
        delayed()
      }
    },
  )

  $root.querySelectorAll('textarea.autoresize').forEach(
    $elem => resizeTextarea($elem),
  )
}

function hideNotification($elem) {
  const $container = $elem.closest('.notification')
  $container.classList.add('is-hidden')
}

function setFormMethod(_help, $elem) {
  const method = $elem.getAttribute('formmethod')
  const $form = getForm($elem)
  if (!$form) {
    console.error('Unable to set formmethod: attribute registered on element without form parent', $elem)
    return
  }
  $form.method = method
}

function defaultSubmit(help, $elem) {
  const $form = getForm($elem)
  if (!$form) {
    return
  }
  if ($elem.hasAttribute('data-async-target')) {
    const replyCallback = (
      $elem.dataset.asyncEvent
        ? () => events.trigger($elem.dataset.asyncEvent, help.getRoot(), $elem.dataset.eventDetail)
        : undefined
    )

    help.ajaxFormLoad($elem, $form, replyCallback)
    $form
      .querySelectorAll('[data-async-clear]')
      .forEach($field => {$field.value = ''})
  } else {
    doFormSubmit($elem, $form)
  }
}

function assignEvents(sourceElement, help) {
  if (help === undefined) {
    help = buildHelp(sourceElement)
  }

  help.live('click', '[formmethod], [data-action], [formaction]', addLoader.bind(null, help))

  help.live('click', '[formmethod]', setFormMethod.bind(null, help))
  help.live('keydown', '[data-enter-target]', dataEnterTarget, true)
  help.live('click', '[formaction]', formAction.bind(null, help))
  help.live('click', '[formenctype]', formEncType.bind(null, help))

  help.live('click', '[data-modal]', loadModal.bind(null, help))
  help.live('click', '[data-close-modal]', dataCloseModal(help))
  help.live('click', '[data-zoom]', dataZoom.bind(null, help))

  help.avoid('click', '.to-be-closed', closeElements)
  help.avoid('click', '.to-be-removed', removeElements(help))

  help.live('click', '.navbar-burger', toggleActive)
  help.live('click', 'article.message button.delete', hideMessage)
  help.live('click', '[data-action]', dataAction.bind(null, help), false)
  help.live('change', '[data-change]', dataChange.bind(null, help))
  help.live('change', 'input.file-input', bulmaUpload)
  help.live('click', '[data-replace]', dataReplace(help))
  help.live('click', '[data-toggler]', dataToggler)
  help.live('click', '[data-modal-template]', showModal.bind(null, help))
  help.live('click', '[data-modal-remote]', showRemote.bind(null, help))
  help.live('click', '[data-alt-icon]', dataAltIcon.bind(null, help))
  help.live('click', '[data-show]', toggleHidden(help))
  help.live('click', '[data-tabtoggler]', toggleTab)
  help.live('click', '[data-bulma-dropdown]', toggleDropdown, true)
  help.live('click', '[data-dropdown-template]', showDropdown(help), true)
  help.live('click', '[data-shiftgroup]', dataShiftGroup.bind(null, help), true)
  help.live('click', '.print-new-window', printNewWindow)
  help.live('click', '.do-the-print', () => window.print())
  help.live('click', '.notification button.delete', hideNotification)
  help.live('click', '[data-check-all]', dataCheckAll.onClick.bind(null, help), true)
  help.live('click', '[data-select-onclick]', dataSelectOnclick.bind(null, help), true)

  help.live('mouseover', '[data-trigger-click]', clickMouseover(help))
  help.live('mouseout', '[data-trigger-click]', unclickMouseout(help))

  help.live('input', 'textarea.autoresize', resizeTextarea)
  help.live('keyup', 'input[data-keyup]', dataKeyUp.bind(null, help), true)

  help.live('click', '[formmethod], [data-action], [formaction]', defaultSubmit.bind(null, help))
  help.live('click', '.close-modal', closeModal)

  // perform onload events + watch for changes and apply onload events on
  // newly loaded elements
  onLoadEvents(help, sourceElement)
  new MutationObserver(
    (mutationList) => {
      mutationList
        .filter(({type}) => type === 'childList')
        .forEach(({target}) => onLoadEvents(help, target))
    },
  ).observe(help.getRoot(), {
    attributes: false,
    childList: true,
    subtree: true,
  })
}

export default {
  assignEvents,

  dataChange,
  dataAction,
  doFormSubmit,
  dataCheckAll,
  closeModal,
  defaultSubmit,
}
