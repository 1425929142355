function _updateState(root, qs, $elem) {
  const checkboxes = root.querySelectorAll(qs)
  const count = {
    on: 0,
    off: 0,
  }
  for (const c of checkboxes) {
    if (c.checked) {
      count.on += 1
    } else if (!c.checked) {
      count.off += 1
    }
  }

  applyState($elem, count)
  return count
}

function applyState($elem, count) {
  if (count.on && !count.off) {
    $elem.checked = 'checked'
    $elem.indeterminate = false
  } else if (count.on && count.off) {
    $elem.checked = ''
    $elem.indeterminate = true
  } else if (!count.on && count.off) {
    $elem.checked = ''
    $elem.indeterminate = false
  } else {
    console.warn('Selector does not match any item')
  }
}

function onLoad(help, $elem){
  const root = help.getRoot()
  const qs = $elem.dataset.checkAll
  const state = _updateState(root, qs, $elem)

  $elem.form?.addEventListener('reset', () => {
    // run "applyState" on the next frame, required to execute "applyState" after form is resetted
    setTimeout(() => applyState($elem, state))
  })
  if ($elem instanceof HTMLInputElement) {
    root.addEventListener('change', () => _updateState(root, qs, $elem))
  }
}

function onClick(help, $elem) {
  const root = help.getRoot()
  const qs = $elem.dataset.checkAll
  const checkboxes = root.querySelectorAll(qs)

  const checkElements = (
    ($elem instanceof HTMLInputElement) && $elem.getAttribute('type') === 'checkbox'
      ? $elem.checked
      : !Array.prototype.every.call(checkboxes, $c => $c.checked)
  )

  checkboxes.forEach($c => {$c.checked = checkElements})
}

export default {
  onLoad,
  onClick,
}
