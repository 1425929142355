(() => {
  if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector
  }

  if (!Element.prototype.closest) {
    Element.prototype.closest = function closest(s) {
      let el = this
      do {
        if (el.matches(s)) {
          return el
        }
        el = el.parentElement || el.parentNode
      } while (el !== null && el.nodeType === 1)
      return null
    }
  }

  ((arr) => {
    arr.forEach((item) => {
      if (Object.prototype.hasOwnProperty.call(item, 'remove')) {
        return
      }
      Object.defineProperty(item, 'remove', {
        configurable: true,
        enumerable: true,
        writable: true,
        value: function remove() {
          if (this.parentNode === null) {
            return
          }
          this.parentNode.removeChild(this)
        },
      })
    })
  })([Element.prototype, CharacterData.prototype, DocumentType.prototype])
})()
